import React, { useContext, useEffect } from 'react'
import { BaseImgUrl } from '../../Config'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { breakpoints, related } from '../../JsonData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Rating from 'react-rating';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext';



const FeaturedItems = () => {

  const navigate = useNavigate();

  const { 
    navkar, 
    getNewArrivalProducts,
    newArrivalProducts,
    getBestSellerProducts,
    bestSellerProducts,
  } = useContext(UserContext);

  console.log('newArrivalProducts : ', newArrivalProducts);
  // console.log('bestSellerProducts : ', bestSellerProducts);

  useEffect(() => {
    getNewArrivalProducts();
    getBestSellerProducts();
  }, []);
 
  return (
    <section className='mb-5'>
      <section className='mt-5 mb-3'>
        <div className='d-flex flex-column align-items-center'>
          <h3 className='home-head-1 mb-0'>Featured Items</h3>
          <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>
      </section>

      <div className='position-relative'>
        <img width={250} className='d-none d-lg-block sticker_1' src='/images/sticker1.png' alt='' />
      
        <img width={250} className='d-none d-lg-block sticker_2' src='/images/sticker2.png' alt='' />
      </div>

      <div className='feature_tabs mt-3'>
        <Tabs defaultActiveKey="new_seller" transition={true} id="noanim-tab-example" className="my-3 my-lg-5" >
          {/* New Arrival */}
          <Tab eventKey="new_seller" title="New Arrival">
            <div className='container home_products px-0'>
              <Swiper
                className="mySwiper col-11 mx-0 mt-4 home_featured w-100 px-2"
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={2}
                spaceBetween={20}
                breakpoints={breakpoints}
                modules={[Pagination]}
              >
                {
                  newArrivalProducts && newArrivalProducts.length > 0
                  &&
                  newArrivalProducts.map((product, index) => (
                    <SwiperSlide onClick={() => navigate(`/product/${product._id}`)} key={index}>
                      <div className='position-relative bg-white rounded-4 p-2 shadow-sm '>
                        <img src={product.image} className='w-100 object-fit-contain mb-1 mb-sm-2' style={{ height: '23vh' }} alt="newArrival" />

                        <div className='fs-6 fw-semibold overflow-hidden text-truncate' style={{ height: '25px' }}>{product.productName}</div>

                        {/* Rating */}
                        <Rating
                          className=''
                          initialRating={product.average}
                          readonly
                          emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                          fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                        />

                        <small className='d-block my-1 overflow-hidden text-truncate text-wrap' style={{ height: '37px' }}>{product.description}</small>

                        {/* Size */}
                        <div>
                          <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size}</span>
                        </div>

                        {/* Price */}
                        <div className='my-2'>
                          <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                          <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                        </div>

                        {/* Order or Call */}
                        <div className='d-flex justify-content-evenly mb-2'>
                          {/* Order */}
                          <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2' 
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                            }}
                          >
                            <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                            <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                          </div>

                          {/* Call */}
                          <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2' 
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              window.location.href = `tel:${navkar.number}`;
                            }}
                          >
                            <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                            <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                          </div>
                        </div>

                        {/* New Arrival */}
                        <>
                          {
                            product.newArrival === 'true'
                            &&
                            <img src="/images/icons/newArrival.svg" className='position-absolute top-0 start-0' style={{ height: '30px', borderTopLeftRadius: '13px', borderBottomRightRadius: '13px' }} alt="new arrival" />
                          }
                        </>
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </Tab>

          {/* Best Seller */}
          <Tab eventKey="Best_Seller" title="Best Seller">
            <div className='container home_products px-0'>
              <Swiper
                className="mySwiper col-11 mx-0 mt-4 home_featured w-100 px-2"
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={breakpoints}
                modules={[Pagination]}
              >
                {
                  bestSellerProducts && bestSellerProducts.length > 0
                  &&
                  bestSellerProducts.map((product, index) => (
                    <SwiperSlide onClick={() => navigate(`/product/${product._id}`)} key={index}>
                      <div className='position-relative bg-white rounded-4 p-2 shadow-sm'>
                        <img src={product.image} className='w-100 object-fit-contain mb-1 mb-sm-2' style={{ height: '23vh' }} alt="bestSellerr" />

                        <div className='fs-6 fw-semibold overflow-hidden text-truncate' style={{ height: '25px' }}>{product.productName}</div>

                        {/* Rating */}
                        <Rating
                          className=''
                          initialRating={product.average}
                          readonly
                          emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                          fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                        />

                        <small className='d-block my-1 overflow-hidden text-truncate text-wrap' style={{ height: '37px' }}>{product.description}</small>

                        {/* Size */}
                        <div>
                          <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size}</span>
                        </div>

                        {/* Price */}
                        <div className='my-2'>
                          <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                          <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                        </div>

                        {/* Order or Call */}
                        <div className='d-flex justify-content-evenly mb-2'>
                          {/* Order */}
                          <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2'
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                            }}
                          >
                            <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                            <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                          </div>

                          {/* Call */}
                          <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2'
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              window.location.href = `tel:${navkar.number}`;
                            }}
                          >
                            <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                            <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                          </div>
                        </div>

                        {/* Best Seller */}
                        <>
                          {
                            product.bestSeller === 'true'
                            &&
                            <img src="/images/icons/bestSeller.svg" className='position-absolute top-0 end-0' style={{ height: '30px', borderTopRightRadius: '13px', borderBottomLeftRadius: '13px' }} alt="new arrival" />
                          }
                        </>
                      </div>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}

export default FeaturedItems
