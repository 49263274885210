import React, { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BaseApiUrl } from '../../Config';
import axios from 'axios';


export const UserContext = createContext();



const NavkarContext = ({ children }) => {

  const [navkar, setNavkar] = useState({
    number: '9201623579',
    email: 'navkarnaturally@gmail.com',
    address: 'Navkar, Opp. Hari Bhai Gems and Jewellery, Near Shyam Talkies, Budhapara, Raipur Chhattisgarh',
    instagram: 'https://www.instagram.com/navkar_naturally',
    facebook: 'https://www.facebook.com/navkarnaturally',
    youtube: 'https://www.youtube.com/@Navkarnatural',
  });

  const [name, setName] = useState('Siddharth Dongre');

  const navigate = useNavigate();


  // To handle click offcanvas 
  const handleClickOffCanvas = (path) => {
    // It will close offcanvas on click
    window.bootstrap.Offcanvas.getInstance(document.getElementById('offcanvasNavbar'))?.hide();

    // It will navigate to path
    navigate(path);
  };



  // ---------- Customer Review ----------

  const [customerReview, setCustomerReview] = useState({
    profile: '',
    rating: '',
    name: '',
    review: '',
    number: '',
  });

  const [customerReviews, setCustomerReviews] = useState([]);

  const [showCustomerReviewPopup, setShowCustomerReviewPopup] = useState(false);


  // To handle change customer review
  const handleChangeCustomerReview = (e) => {
    const { name, value } = e.target;

    setCustomerReview(preState => ({
      ...preState,
      [name]: value
    }));
  };

  // To handle customer review rating
  const handleCustomerReviewRating = (value) => {
    setCustomerReview(preState => ({
      ...preState,
      rating: value
    }));
  };

  // To handle submit customer review
  const handleCustomerReviewSubmit = () => {
    if(customerReview.profile === '' || customerReview.rating === '' || customerReview.name === '' || customerReview.review === '' || customerReview.number === '') {
      toast.error('Please fill the form properly');
    } else {
      axios.post(`${BaseApiUrl}/user/addCustomerReview`, customerReview)
      .then(res => {
        setCustomerReview({
          profile: '',
          name: '',
          rating: '',
          review: '',
          number: '',
          otp: '',
        });

        setShowCustomerReviewPopup(false);

        toast.success(res.data.message);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // To get customer reviews
  const getCustomerReviews = () => {
    axios.post(`${BaseApiUrl}/user/getCustomerReviews`)
    .then(res => {
      setCustomerReviews(res.data.customerReviews);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  const getAllBanner = async () => {
    const data = await axios.post(`${BaseApiUrl}/admin/getBanner`);
    return data.data.bannerData
  };



  // ---------- Contact ----------

  const [contact, setContact] = useState({
    fname: '',
    lname: '',
    email: '',
    number: '',
    message: '',
  });


  // To handle contact form submit
  const handleContactSubmit = () => {
    axios.post(`${BaseApiUrl}/user/addContact`, contact)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      setContact({
        fname: '',
        lname: '',
        email: '',
        number: '',
        message: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Products ----------

  const [products, setProducts] = useState([]);

  // get products
  const getProducts = (categoryId) => {
    axios.post(`${BaseApiUrl}/user/getProducts`, { categoryId })
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setProducts(res.data.products);
    }).catch(err => {
      console.log('err : ', err);
    });
  };


  // ---------- Product ----------

  const [showProductReviewPopup, setShowProductReviewPopup] = useState(false);
  const [showAvtarPopup, setShowAvtarPopup] = useState(false);
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productReviews, setProductReviews] = useState([]);
  const [productReviewDetail, setProductReviewDetail] = useState({});

  const [productReview, setProductReview] = useState({
    productId: '',
    profile: '',
    rating: '',
    name: '',
    review: '',
    number: '',
    // attachment: [],
    // otp: '',
  });

  // get product
  const getProduct = (productId) => {
    axios.post(`${BaseApiUrl}/user/getProduct`, { productId })
    .then(res => {
      // console.log('res : ', res.data);

      // toast.success(res.data.message);

      setProduct(res.data.product);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get related products
  const getRelatedProducts = (productId) => {
    axios.post(`${BaseApiUrl}/user/getRelatedProducts`, { productId })
    .then(res => {
      // console.log('res : ', res.data);

      // toast.success(res.data.message);

      setRelatedProducts(res.data.relatedProducts);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // add product review
  const addProductReview = () => {
    const formData = new FormData();

    formData.append('productId', product._id);
    formData.append('profile', productReview.profile);
    formData.append('rating', productReview.rating);
    formData.append('name', productReview.name);
    formData.append('review', productReview.review);
    formData.append('number', productReview.number);

    axios.post(`${BaseApiUrl}/user/addProductReview`, formData)
    .then(res => {
      console.log('res : ', res.data);

      toast.success(res.data.message);

      setShowProductReviewPopup(false);

      setProductReview({
        profile: '',
        rating: '',
        name: '',
        review: '',
        number: '',
      });

      setProductReviews(prevReviews => [res.data.addReview, ...prevReviews]);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get product reviews
  const getProductReviews = (productId) => {
    axios.post(`${BaseApiUrl}/user/getProductReviews`, { productId })
    .then(res => {
      // console.log('res : ', res.data);

      // toast.success(res.data.message);

      setProductReviews(res.data.reviews);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get product review detail
  const getProductReviewDetail = (productId) => {
    axios.post(`${BaseApiUrl}/user/getProductReviewDetail`, { productId })
    .then(res => {
      // console.log('res : ', res.data);

      // toast.success(res.data.message);

      setProductReviewDetail(res.data.detail);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  
  
  // ---------- Home ----------

  const [categories, setCategories] = useState([]);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [homeProducts, setHomeProducts] = useState([]);

  // get categories
  const getCategories = () => {
    axios.post(`${BaseApiUrl}/admin/getCategories`)
    .then(res => {
      // console.log('res : ', res.data);

      // toast.success(res.data.message);

      setCategories(res.data.categories);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get home new arrival products
  const getNewArrivalProducts = () => {
    axios.post(`${BaseApiUrl}/user/getNewArrivalProducts`)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setNewArrivalProducts(res.data.products);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get home new arrival products
  const getBestSellerProducts = () => {
    axios.post(`${BaseApiUrl}/user/getBestSellerProducts`)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setBestSellerProducts(res.data.products);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // get home products
  const getHomeProducts = () => {
    axios.post(`${BaseApiUrl}/user/getHomeProducts`)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setHomeProducts(res.data.products);
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  const value = {
    navkar, setNavkar,
    name, setName,
    handleClickOffCanvas,
    navigate,


    // ----- Home -----
    categories, setCategories,
    newArrivalProducts, setNewArrivalProducts,
    bestSellerProducts, setBestSellerProducts,
    homeProducts, setHomeProducts,
    getCategories,
    getNewArrivalProducts,
    getBestSellerProducts,
    getHomeProducts,


    // ----- Product -----
    showProductReviewPopup, setShowProductReviewPopup,
    showAvtarPopup, setShowAvtarPopup,
    product, setProduct,
    relatedProducts, setRelatedProducts,
    productReview, setProductReview,
    productReviews, setProductReviews,
    productReviewDetail, setProductReviewDetail,
    getProduct,
    getRelatedProducts,
    addProductReview,
    getProductReviews,
    getProductReviewDetail,


    // ----- Products -----
    products, setProducts,
    getProducts,


    // ----- Customer Review -----
    customerReviews, setCustomerReviews,
    showCustomerReviewPopup, setShowCustomerReviewPopup,
    customerReview, setCustomerReview,
    handleChangeCustomerReview,
    handleCustomerReviewRating,
    // handleCustomerReviewOtp,
    handleCustomerReviewSubmit,
    getCustomerReviews,
    getAllBanner,

    // ----- Contact -----
    contact, setContact,
    handleContactSubmit,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default NavkarContext
