import React, { useContext, useState } from 'react'
import { BaseImgUrl } from '../../Config'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { reviewbreakpoints } from '../../JsonData';
import Rating from 'react-rating';
import CustomerReviewPopup from '../Popup/CustomerReviewPopup';
import { UserContext } from '../UseContext/NavkarContext';

import 'swiper/css';
import 'swiper/css/pagination';


const CustomersReviews = () => {

  const [expandedReviews, setExpandedReviews] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});

  const {
    customerReviews,
    showCustomerReviewPopup, setShowCustomerReviewPopup,
  } = useContext(UserContext);


  // To handle show more less review
  const handleExpandReview = (index) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  // To handle show more less reply
  const handleExpandReply = (index) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <>
      {/* Customer Review Popup */}
      <CustomerReviewPopup />

      <section className='mt-5'>
        <div className=' my-3'>
          <div className='d-flex flex-column align-items-center'>
            <h3 className='home-head-1 mb-0'>Customer Review</h3>
            <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
            <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
          </div>
        </div>

        <div className="flex justify-content-center">
          <button className='pro_see_all fw-semibold bg-transparent' onClick={() => setShowCustomerReviewPopup(true)}>Write Review</button>
        </div>

        <div className='container my-2 my-lg-5 px-0'>
          <Swiper
            className="mySwiper col-11 mx-0 my-3 review_slider w-100 pb-4"
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            slidesPerView={1}
            spaceBetween={10}
            autoHeight={true}
            breakpoints={reviewbreakpoints}
            modules={[Pagination]}
          >
            <div className='position-relative'>
              {customerReviews.map((review, i) => (
                <SwiperSlide key={i} className='review_slide bg-white rounded-3 d-flex flex-column align-items-center px-4'>
                  <div className='review_card shadow-sm pt-5 pb-3'>
                    {/* Profile */}
                    <div className='review_img rounded-pill shadow-sm'>
                      <img src={review.profile} className='object-fit-cover' width={70} height={70} alt="profile" />
                    </div>

                    {/* Name */}
                    <p className='fw-semibold text-capitalize'>{review.name}</p>

                    {/* Rating */}
                    <Rating
                      className=''
                      initialRating={review.rating}
                      readonly
                      emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '17px' }} alt="empty" />}
                      fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '17px' }} alt="fill" />}
                    />

                    {/* Review */}
                    <div className='mt-2'>
                      <span>{expandedReviews[i] ? review.review : review.review.split(' ').slice(0, 20).join(' ')}</span>

                      {
                        review.review.split(' ').length > 20
                        &&
                        <span className='text-primary cursor-pointer' onClick={() => handleExpandReview(i)}> show {expandedReviews[i] ? 'less' : 'more'}...</span>
                      }
                    </div>

                    {/* Reply */}
                    <>
                      {
                        review.reply.length > 0
                        &&
                        <div className='bg-success-subtle p-2 rounded-3 mt-3 w-100'>
                          <div className='mb-2'>
                            <img src={`${BaseImgUrl}/icons/reply.svg`} className='me-3' alt="reply" />
                            <span className='text-success'>Reply</span>
                          </div>

                          <span>{expandedReplies[i] ? review.reply : review.reply.split(' ').slice(0, 20).join(' ')}</span>

                          {
                            review.reply.split(' ').length > 20
                            &&
                            <span className='text-primary cursor-pointer' onClick={() => handleExpandReply(i)}> show {expandedReplies[i] ? 'less' : 'more'}...</span>
                          }
                        </div>
                      }
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default CustomersReviews
