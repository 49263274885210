import React, { useContext, useEffect } from 'react';
import { breakpoints } from '../../JsonData.js';
import { Pagination } from 'swiper/modules';
import Rating from 'react-rating';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseImgUrl } from '../../Config.js';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { UserContext } from '../UseContext/NavkarContext.js';



const RelatedProducts = () => {

  const navigate = useNavigate();

  const { productId } = useParams();

  const { 
    relatedProducts,
    getRelatedProducts,
  } = useContext(UserContext);

  useEffect(() => {
    getRelatedProducts(productId);
  }, [productId]);


  return (
    <>
      {
        relatedProducts && relatedProducts.length > 0
        &&
        <section className='container px-0'>
          <h3 className='ps-3 mt-3 text-success'>Related Products</h3>

          <div className='container home_products px-0'>
            <Swiper
              className="mySwiper col-11 mx-0 mt-3 w-100 p-2"
              pagination={{
                dynamicBullets: true,
              }}
              slidesPerView={1}
              spaceBetween={20}
              breakpoints={breakpoints}
              modules={[Pagination]}
            >
              {
                relatedProducts && relatedProducts.length > 0
                &&
                relatedProducts.map((product, index) => (
                  <SwiperSlide onClick={() => navigate(`/product/${product._id}`)} key={index}>
                    <div className='bg-white rounded-4 p-2 shadow-sm'>
                      <img src={product.image} className='w-100 object-fit-contain mb-1 mb-sm-2' style={{ height: '23vh' }} alt="aachar" />

                      <div className='fs-6 fw-semibold overflow-hidden text-truncate' style={{ height: '25px' }}>{product.productName}</div>

                      {/* Rating */}
                      <Rating
                        className=''
                        initialRating={4}
                        readonly
                        emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                        fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                      />

                      <small className='d-block my-1 overflow-hidden text-truncate text-wrap' style={{ height: '37px' }}>{product.description}</small>

                      {/* Size */}
                      <div>
                        <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size}</span>
                      </div>

                      {/* Price */}
                      <div className='my-2'>
                        <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹{product.price1}.00</span>
                        <span className='text-decoration-line-through text-secondary'>₹{product.price2}.00</span>
                      </div>

                      {/* Order or Call */}
                      <div className='d-flex justify-content-evenly mb-2'>
                        {/* Order */}
                        <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2'>
                          <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                          <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                        </div>

                        {/* Call */}
                        <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2'>
                          <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                          <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </section>
      }
    </>
  )
}

export default RelatedProducts
